/* eslint-disable camelcase */

import { gql, TypedDocumentNode } from '@apollo/client';
import {
    CART_FIELDS,
    CART_FIELDS_LESS_INFO,
} from './fragments';

interface ICartFieldsLessInfo {
    id: string;
    items: {
        id: number;
        product: {
            name: string;
            sku: string;
        };
        quantity: number;
    }[];
}

interface ICustomerCartOutput {
    customerCart: ICartFieldsLessInfo;
}

interface ICustomerCartWithIdOnlyOutput {
    customerCart: {
        id: string;
    };
}

export interface IAddToCartItem {
    sku: string;
    quantity: number;
    parent_sku?: string;
    selected_options?: string[];
    entered_options?: string[];
}

interface IAddProductsToCartInput {
    cartId: string;
    cartItems: IAddToCartItem[];
}

export interface IUserError {
    message: string;
    code: string;
}

interface IAddProductsToCartOutput {
    addProductsToCart: {
        cart: ICartFieldsLessInfo;
        user_errors: IUserError[];
    };
}

export const GET_CART: TypedDocumentNode = gql`
    ${CART_FIELDS}
    query GET_CART(
        $cartId: String!
    ) {
        cart(
            cart_id: $cartId
        ) {
            ...CartFields
        }
    }
`;

export const GET_CUSTOMER_CART: TypedDocumentNode<ICustomerCartOutput, {}> = gql`
    ${CART_FIELDS_LESS_INFO}
    query GET_CUSTOMER_CART {
        customerCart {
            ...CartFieldsLessInfo
        }
    }
`;

export const GET_CUSTOMER_CART_WITH_ID_ONLY: TypedDocumentNode<ICustomerCartWithIdOnlyOutput, {}> = gql`
    query GET_CUSTOMER_CART {
        customerCart {
            id
        }
    }
`;

export const ADD_PRODUCTS_TO_CART_MUTATION: TypedDocumentNode<IAddProductsToCartOutput, IAddProductsToCartInput> = gql`
    ${CART_FIELDS_LESS_INFO}
    mutation addProductsToCart(
        $cartId: String!,
        $cartItems: [CartItemInput!]!
    ) {
        addProductsToCart(
            cartId: $cartId,
            cartItems: $cartItems
        ) {
            cart {
                ...CartFieldsLessInfo,
            }
            user_errors {
                message
                code
            }
        }
    }
`;

export const REMOVE_FROM_CART_MUTATION: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation REMOVE_FROM_CART_MUTATION($input: RemoveItemFromCartInput) {
        removeItemFromCart(input: $input) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const UPDATE_CART_MUTATION: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation UPDATE_CART_MUTATION($input: UpdateCartItemsInput) {
        updateCartItems(input: $input) {
            cart {
                ...CartFields
            }
        }
    }
`;
