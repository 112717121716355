import React, { useRef } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import style from './styles.css';

interface PopupProps {
    isVisible: boolean;
    onClose: () => void;
    className?: string;
    classNameContentCnt?: string;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({
    isVisible,
    onClose,
    className,
    classNameContentCnt,
    children,
}) => {
    const popupRef = useRef<HTMLDivElement | null>(null);

    if (!isVisible) return null;

    return (
        <div className={classes(style.popupOverlay, (className || ''))}>
            <div className={classes(style.popupContent, (classNameContentCnt || ''))} ref={popupRef} id="qa-popup">
                <button type="button" className={style.closeBtn} onClick={onClose}>X</button>
                {children}
            </div>
        </div>
    );
};

export default Popup;
