import React from 'react';
import { useDesignConfig } from '@silkpwa/module/design-config';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface ILogoProps {
    className?: string;
}

export const Logo: React.FC<ILogoProps> = ({ className }) => {
    const designConfig = useDesignConfig();
    const title = `${designConfig.pageTitle}`;
    const logoUrl = designConfig.logoUrl ?? '';
    const logoBackgroundImage = {
        backgroundImage:
            `url(${logoUrl})`,
    };
    return (
        <a href="/" style={logoBackgroundImage} className={classes(styles.logoLink, (className || ''))}>{title}</a>
    );
};
