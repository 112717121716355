/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';

export interface IAvailableRegion {
    id: number;
    code: string;
    name: string;
}

export interface ICountry {
    id: string;
    two_letter_abbreviation: string;
    three_letter_abbreviation: string;
    full_name_locale: string;
    full_name_english: string;
    available_regions: IAvailableRegion[]|null;
}

export interface ICountriesOutput {
    countries: ICountry[];
}

export const GET_AVAILABLE_COUNTRIES: TypedDocumentNode<ICountriesOutput, {}> = gql`
    query GET_AVAILABLE_COUNTRIES {
        countries {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
            available_regions {
                id
                code
                name
            }
        }
    }
`;
