import React from 'react';
import { ICartRepository } from '@silkpwa/magento/api/cart-repository/repository';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import {
    ICartItem,
    BaseProduct,
    IBaseProductConfigProps,
} from '@silkpwa/module/react-component/product-config/base-product';
import { IBundleOptionData, IBundleSelectionData } from 'chefworks-theme/src/ui/component/product-configurator/product-config/bundle-config/bundle-option';
import { checkBundleSelections } from '../util';
import { RenderConfigurator } from '../render-configurator';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

interface ICalculcatedPrices {
    calculatedPrice: number;
    calculatedOriginalPrice: number;
}

export class BundleProductConfig extends BaseProduct {
    constructor(props: Readonly<IBaseProductConfigProps>) {
        super(props);

        const { product } = this.props;

        this.state = {
            ...this.state,
            selectionsQty: {},
            selectedProductNames: {},
            calculatedPrice: 0,
            calculatedOriginalPrice: 0,
            dynamicSku: product.sku,
        };

        this.setSelections = this.setSelections.bind(this);
        this.areBundleOptionsSelected = this.areBundleOptionsSelected.bind(this);
        this.setSelectedProductNames = this.setSelectedProductNames.bind(this);
    }

    get selectionsQty(): IProductConfigData['selections'] {
        const { selectionsQty } = this.state;
        return selectionsQty;
    }

    get selectedProductNames(): IProductConfigData['selectedProductNames'] {
        const { selectedProductNames } = this.state;
        return selectedProductNames;
    }

    setSelectedProductNames(optionId: string|number, productName: string) {
        const { selectedProductNames } = this;
        if (selectedProductNames) {
            selectedProductNames[Number(optionId)] = productName;
        }
        this.setState({
            selectedProductNames,
        });
    }

    setSelections(key: string, value: string, qty?: string, inputType?: string, index?: number) {
        const { selections } = this;
        const { selectionsQty } = this;

        if (inputType === 'multi' && value !== '') {
            if (selections[key] && selections[key].length) {
                // when valid index value is available for multi type we only need to update that index
                if (index !== undefined || index >= 0) {
                    selections[key][index] = value;
                } else {
                    selections[key] = [...selections[key], value];
                }
            } else {
                selections[key] = [value];
            }
        } else {
            selections[key] = value;
        }

        if (typeof qty !== 'undefined') {
            selectionsQty[key] = qty;
        }

        const calculatedPrices = this.getCalculatedPriceBasedOnSelections(
            selections, selectionsQty,
        );
        const calculatedPrice = calculatedPrices
            ? calculatedPrices.calculatedPrice : 0;
        const calculatedOriginalPrice = calculatedPrices
            ? calculatedPrices.calculatedOriginalPrice : 0;

        const dynamicSku = this.generateDynamicSku(selections);

        this.setState({
            selections,
            selectionsQty,
            calculatedPrice,
            calculatedOriginalPrice,
            dynamicSku,
        });
    }

    generateDynamicSku(selections: IProductConfigData['selections']): string {
        const { product } = this.props;
        let dynamicSku = product.sku;

        product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
            if (selections[bundleOption.id] === undefined) {
                return;
            }

            const selectedId = selections[bundleOption.id];
            bundleOption.selections.forEach(((selection) => {
                if (Array.isArray(selectedId)) {
                    if (selectedId.includes(selection.selection_id)) {
                        dynamicSku += `-${selection.sku}`;
                    }
                } else if (selection.selection_id === selectedId) {
                    dynamicSku += `-${selection.sku}`;
                }
            }));
        });

        return dynamicSku;
    }

    areBundleOptionsSelected(selections?: IProductConfigData['selections']): boolean {
        let result = true;
        const { product } = this.props;
        const bundleSelections = selections || this.selections;

        product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
            if (
                bundleOption.required === '1' &&
                bundleSelections[bundleOption.id] === undefined
            ) {
                result = false;
            }
        });
        return result;
    }

    getCalculatedPriceBasedOnSelections(
        selections: IProductConfigData['selections'],
        selectionsQty: IProductConfigData['selections'],
    ): ICalculcatedPrices | null {
        if (this.areBundleOptionsSelected(selections)) {
            return this.calculateTotalPriceFromSelections(selections, selectionsQty);
        }

        return null;
    }

    calculateTotalPriceFromSelections(
        selections: IProductConfigData['selections'],
        selectionsQty: IProductConfigData['selections'],
    ): ICalculcatedPrices {
        const { product } = this.props;
        let result = 0;
        let originalResult = 0;
        product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
            const bundleOptionId = parseInt(bundleOption.id, 10);
            if (selections[bundleOptionId] !== undefined) {
                const selectedBundleOptionIds = Array.isArray(selections[bundleOptionId])
                    ? selections[bundleOptionId] : [selections[bundleOptionId]];
                selectedBundleOptionIds.forEach((selectedBundleOptionId) => {
                    const selectedBundleProduct = bundleOption.selections.find(
                        (selection: IProductConfigData['selections']) => selection.selection_id === selectedBundleOptionId,
                    );
                    if (selectedBundleProduct !== undefined) {
                        const quantity = selectionsQty[bundleOptionId]
                            ? parseInt(selectionsQty[bundleOptionId], 10) : 1;
                        result += parseFloat(selectedBundleProduct.price.toString()) * quantity;
                        originalResult += parseFloat(selectedBundleProduct.original_price.toString()) * quantity;
                    }
                });
            }
        });

        return {
            calculatedPrice: result,
            calculatedOriginalPrice: originalResult,
        };
    }


    protected handleEditItem() {
        const item = this.cartItem;
        /* eslint-disable camelcase */
        const { selections, selections_qty } = item;

        Object.keys(selections).forEach((optionId) => {
            const selectionsOptionIds = selections[optionId];
            // find if multiple options are selected
            const inputType = Array.isArray(selectionsOptionIds) ? 'multi' : '';
            if (inputType === 'multi') {
                if (selections_qty && parseInt(selections_qty[optionId] as string, 10) === 2) {
                    // in case of double strap qty is 2 from backend which will double the price calculation
                    // reduce qty to 1 when multiple option is selected so each bundle option will calculate once #177
                    if (selectionsOptionIds?.length === 2) {
                        selections_qty[optionId] = 1;
                    }
                    // add selection option for double option selection on edit
                    // because when same color option is selected. option value is single from backend
                    // we need to add option here #185 to correctly calculate price
                    // we also need that to select same option multiple times #185
                    // same as above condition also need to reduce the qty as we added the option individually #186
                    if (selectionsOptionIds?.length === 1) {
                        selectionsOptionIds.push(selectionsOptionIds[0]);
                        selections_qty[optionId] = 1;
                    }
                }
                selectionsOptionIds.forEach((selectionsOptionId) => {
                    this.setSelections(
                        optionId.toString(),
                        selectionsOptionId.toString(),
                        selections_qty && selections_qty[optionId]
                            ? selections_qty[optionId].toString() : '1',
                        inputType,
                    );
                });
            } else {
                this.setSelections(
                    optionId.toString(),
                    selections[optionId].toString(),
                    selections_qty && selections_qty[optionId]
                        ? selections_qty[optionId].toString() : '1',
                );
            }
        });
        /* eslint-enable camelcase */

        this.setState({
            quantity: item.qty,
        });
    }

    protected handleNewItem() {
        const { product } = this.props;
        product.bundledProducts.forEach((option: IBundleOptionData) => {
            option.selections.forEach((selection: IBundleSelectionData) => {
                if (selection.is_default === '1') {
                    this.setSelections(option.id, selection.selection_id);
                }
            });
        });
    }

    private collectBundleSelectionsQty() {
        const { product } = this.props;
        const bundleSelectionsQty: { [key: string]: any } = {};
        product.bundledProducts.forEach((option: IBundleOptionData) => {
            const selected = Array.isArray(this.selections[option.id])
                ? this.selections[option.id] : [this.selections[option.id]];
            if (selected) {
                option.selections.forEach((selection: IBundleSelectionData) => {
                    if (selected.includes(selection.selection_id)) {
                        // If user cannot change quantity, assign the default quantity
                        if (selection.can_change_qty === '0') {
                            bundleSelectionsQty[option.id] = selection.default_qty;
                        } else {
                            const { selectionsQty } = this;
                            bundleSelectionsQty[option.id] = selectionsQty[option.id];
                        }
                    }
                });
            }
        });

        return bundleSelectionsQty;
    }

    protected async addItemToCart(repository: ICartRepository, item?: SilkRestappDataCartCartItemInterface|ICartItem) {
        const { productId } = this.props;
        if (!productId) {
            throw new Error('No Product Id assigned');
        }

        const bundleSelectionsQty = this.collectBundleSelectionsQty();

        await repository.addBundleProduct(
            productId,
            this.selections,
            this.quantity.current,
            bundleSelectionsQty,
            /**
             * See description and logic in parent method `updateItem` and `addItemToCart` in:
             * `../base-product/base-product.tsx`
             */
            item,
        );
    }

    async addToCart(repository: ICartRepository) {
        const {
            itemId,
            close,
            t,
            enqueue,
        } = this.props;

        try {
            if (!checkBundleSelections(this)) {
                enqueue({
                    type: 'primary',
                    message: t('Not all required options were selected'),
                    time: 5000,
                });
                return;
            }
            if (itemId && this.quantity.current === 0) {
                await this.removeItem(repository);
            } else if (itemId) {
                await this.updateItem(repository);
            } else {
                await this.addNewItem(repository);
            }

            if (close) {
                close();
            }
        } finally {
            this._adding = false;
        }
    }

    render() {
        const { children } = this.props;
        const {
            calculatedPrice, calculatedOriginalPrice, dynamicSku, validation, reviews,
        } = this.state;
        this.data = {
            ...this.renderData(),
            type: 'BundleConfig',
            dynamicSku,
            setSelections: this.setSelections,
            calculatedPrice,
            calculatedOriginalPrice,
            areBundleOptionsSelected: this.areBundleOptionsSelected,
            selectionsQty: this.selectionsQty,
            selectedProductNames: this.selectedProductNames,
            setSelectedProductNames: this.setSelectedProductNames,
            validation,
            reviews,
        };

        return (
            <RenderConfigurator data={this.data}>
                {children}
            </RenderConfigurator>
        );
    }
}
