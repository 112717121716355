import React, { useEffect, useState } from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import { useContainerHook } from '@silkpwa/redux';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import uploadLogo from 'assets/images/upload.svg';
import { MultipleImageUploader } from 'ui/component/multiple-image-uploader';
import { Preview } from '../../components/preview';
import { VideoUploadButton } from './video-upload-button';
import { VideoPreview } from '../../components/preview/video-preview';
import { ImageUploadResult, ReviewState, VideoUploadResult } from '../../review-form';
import styles from './style.css';

interface UploaderProps {
    initialReview: ReviewState;
    uploadImages: (data: FormData) => ImageUploadResult;
    uploadVideos: (data: FormData) => VideoUploadResult;
    onImageUpload: (newImages: ImageUploadResult[]) => void;
    onVideoUpload: (newVideo: VideoUploadResult[]) => void;
    setIsUploading: (isUploading: boolean) => void;
}
export const Uploader: React.FC<UploaderProps> = ({
    initialReview, uploadImages, uploadVideos, onImageUpload, onVideoUpload, setIsUploading,
}) => {
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const maxCountImages = 10;
    const maxCountVideo = 3;
    const imageMediaType = '1';
    const videoMediaType = '2';
    const maxImageFileSizeMB = 5;
    const maxVideoFileSizeMB = 100;
    const enqueueNotification = useContainerHook<() => any>('useEnqueueNotification');
    const t = usePhraseTranslater();

    const isDisabled = loadingImage || loadingVideo;
    useEffect(() => {
        setIsUploading(isDisabled);
    }, [isDisabled, setIsUploading]);

    const uploadAction = async (uploadFiles: FileList): Promise<void> => {
        setLoadingImage(true);
        try {
            if (!uploadFiles.length) {
                return;
            }
            const existingImagesCount = initialReview.uploadedImageData.length;
            const availableSlots = maxCountImages - existingImagesCount;

            const formData = new FormData();
            for (let x = 0; x < Math.min(uploadFiles.length, availableSlots); x += 1) {
                const fileSizeMB = uploadFiles[x].size / (1024 * 1024);
                if (fileSizeMB <= maxImageFileSizeMB) {
                    formData.append('fileToUpload[]', uploadFiles[x]);
                } else {
                    enqueueNotification({
                        type: 'primary',
                        message: t(`File ${uploadFiles[x].name} exceeds the maximum size of ${maxImageFileSizeMB} MB and will be skipped.`),
                        time: 5000,
                    });
                }
            }
            if (formData.has('fileToUpload[]')) {
                formData.append('media_type', imageMediaType);
                const uploadedImageData = await uploadImages(formData);
                const newImages = [...initialReview.uploadedImageData, uploadedImageData];

                if (newImages) {
                    onImageUpload(newImages);
                }
            }
        } finally {
            setLoadingImage(false);
        }
    };

    const uploadVideoAction = async (ev) => {
        setLoadingVideo(true);
        ev.preventDefault();
        try {
            const uploadedFiles: FileList = ev.target.files;
            if (!uploadedFiles.length) {
                return;
            }
            const existingVideosCount = initialReview.uploadedVideoData.length;
            const availableSlots = maxCountVideo - existingVideosCount;

            const formData = new FormData();
            formData.append('media_type', videoMediaType);

            const videoPromises = Array.from(uploadedFiles)
                .slice(0, availableSlots).map((file, i) => new Promise<void>((resolve, reject) => {
                    const fileSizeMB = file.size / (1024 * 1024);
                    if (fileSizeMB > maxVideoFileSizeMB) {
                        enqueueNotification({
                            type: 'primary',
                            message: t(`File ${file.name} exceeds the maximum size of ${maxVideoFileSizeMB} MB and will be skipped.`),
                            time: 5000,
                        });
                        resolve();
                        return;
                    }

                    const fileURL = URL.createObjectURL(file);
                    const video = document.createElement('video');
                    video.preload = 'auto';
                    video.src = fileURL;
                    video.load();
                    video.addEventListener('loadeddata', () => {
                        if (video.readyState >= 3) {
                            video.currentTime = 1;
                        }
                    });
                    video.addEventListener('seeked', () => {
                        try {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            canvas.width = 400;
                            canvas.height = 400;
                            const scaleSize = Math.min(video.videoWidth, video.videoHeight) / 400;
                            const scaledWidth = video.videoWidth / scaleSize;
                            const scaledHeight = video.videoHeight / scaleSize;
                            const dx = (400 - scaledWidth) / 2;
                            const dy = (400 - scaledHeight) / 2;
                            ctx.drawImage(
                                video,
                                0,
                                0,
                                video.videoWidth,
                                video.videoHeight,
                                dx,
                                dy,
                                scaledWidth,
                                scaledHeight,
                            );
                            const imageDataUrl = canvas.toDataURL('image/jpeg');

                            const dataURLtoBlob = (dataurl) => {
                                const arr = dataurl.split(',');
                                const mime = arr[0].match(/:(.*?);/)[1];
                                const bstr = atob(arr[1]);
                                const n = bstr.length;
                                const u8arr = new Uint8Array(n);
                                for (let i = 0; i < n; i += 1) {
                                    u8arr[i] = bstr.charCodeAt(i);
                                }
                                return new Blob([u8arr], { type: mime });
                            };

                            const imageBlob = dataURLtoBlob(imageDataUrl);
                            const originalFileName = file.name.split('.').slice(0, -1).join('.');
                            const uniqueHash = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
                            const thumbnailFileName = `${originalFileName}_${uniqueHash}.jpg`;

                            const imageFile = new File([imageBlob], thumbnailFileName, { type: 'image/jpeg' });
                            formData.append(`files[videoFile_${i}]`, file);
                            formData.append(`files[previewImage_${i}]`, imageFile);
                            resolve();
                        } catch (error) {
                            reject(error);
                        } finally {
                            URL.revokeObjectURL(video.src);
                        }
                    });
                }));

            await Promise.all(videoPromises);
            const uploadedData = await uploadVideos(formData);
            const newVideos = [...initialReview.uploadedVideoData];
            newVideos.push(uploadedData);

            if (newVideos) {
                onVideoUpload(newVideos);
            }
        } finally {
            setLoadingVideo(false);
        }
    };


    return (
        <div>
            <div className="image-review">
                <div className={styles.loaderImage}>
                    {loadingImage && <LoadingImage />}
                </div>
                {initialReview.uploadedImageData && initialReview.uploadedImageData.map((image, i) => (
                    <div>
                        <Preview
                            image={image}
                            initialReview={initialReview}
                            onImageUpload={onImageUpload}
                            logo={image}
                            rotation={image.rotate || 0}
                            index={i}
                        />
                    </div>
                ))}
                {initialReview.uploadedImageData.length < maxCountImages && (
                    <MultipleImageUploader
                        handleFileChange={uploadAction}
                        wrapperClassName={styles.uploaderLabel}
                        inputId="pr-media_image"
                        inputClassName={styles.uploaderInput}
                        inputName="pr-media_image"
                        inputLabel={t('Add an Image')}
                        inputDataTest="image-upload"
                        inputDataTestNew="rvw-image-upload-btn"
                        btnLabel={t('Upload')}
                        btnClassName={styles.prBtnFileInput}
                        btnIconSrc={uploadLogo}
                        btnIconClassName={styles.uploaderImg}
                        disabled={isDisabled}
                    />
                )}
            </div>
            <div className="video-review">
                <div className={styles.loaderImage}>
                    {loadingVideo && <LoadingImage />}
                </div>
                {initialReview.uploadedVideoData && initialReview.uploadedVideoData.map((image, i) => (
                    <div>
                        <VideoPreview
                            image={image}
                            initialReview={initialReview}
                            onVideoUpload={onVideoUpload}
                            logo={image}
                            rotation={image.rotate || 0}
                            index={i}
                        />
                    </div>
                ))}
                {initialReview.uploadedVideoData.length < maxCountVideo && (
                    <VideoUploadButton
                        dataTest="video-poster-upload"
                        dataTestNew="rvw-video-upload-btn"
                        handleChange={ev => uploadVideoAction(ev)}
                        disabled={isDisabled}
                    />
                )}
            </div>
        </div>
    );
};
