import React from 'react';
import { ICartRepository } from '@silkpwa/magento/api/cart-repository/repository';
import {
    ICartItem,
    BaseProduct,
    IBaseProductConfigProps,
} from '@silkpwa/module/react-component/product-config/base-product';
import { getEnableCommitInfo } from 'chefworks-theme/src/ui/util/get-enable-commit-info';
import { memoize } from '../../../util/memoize';
import { computeSimpleProduct } from '../../../util/compute-simple-product';
import {
    getColors,
    computeProductAttributes,
    IProductAttribute,
    checkSelections,
} from '../util';
import { RenderConfigurator } from '../render-configurator';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

export class ConfigurableProductConfig extends BaseProduct {
    private readonly computeSimpleProduct;

    private _minQuantity;

    private readonly computeProductAttributes;

    constructor(props: Readonly<IBaseProductConfigProps>) {
        super(props);

        this.computeProductAttributes = memoize(computeProductAttributes);
        this.computeSimpleProduct = memoize(computeSimpleProduct);
        this.doEffect = this.doEffect.bind(this);
    }

    componentDidMount() {
        this.handleSingleOptionSelect();
        super.componentDidMount();
    }

    componentDidUpdate() {
        this.handleQuantityChange();
    }

    get attributes(): IProductAttribute[] {
        const { product } = this.props;

        return this.computeProductAttributes(
            product,
            this.selections,
            this.selectOption.bind(this),
        );
    }

    protected get simpleProductId() {
        const { product } = this.props;

        return this.computeSimpleProduct(product, this.selections);
    }

    get colorProductId() {
        const { product } = this.props;
        const colors = getColors(product);
        if (!colors) return undefined;
        const selection = this.selections[colors.id];
        if (!selection) return undefined;
        return product.colorIndex[selection];
    }

    get imageProductId() {
        const { product } = this.props;
        return (
            this.simpleProductId ||
            this.colorProductId ||
            product.id
        );
    }

    handleEditItem() {
        const item = this.cartItem;

        const selections = {};
        item.selections.forEach(([k, v]) => {
            selections[k] = v;
        });

        this.setState({
            selections,
            quantity: item.qty,
        }, this.doEffect);
    }

    handleQuantityChange() {
        if (this.simpleProduct.minQuantity !== this._minQuantity) {
            this._minQuantity = this.simpleProduct.minQuantity;
            if (this.cartItem) return;
            this.setState({
                quantity: this.simpleProduct.minQuantity,
            });
        }
    }

    protected handleNewItem() {
        this.handleQuantityChange();
    }

    handleSingleOptionSelect() {
        const { product } = this.props;
        const selections: {[key: string]: any} = {};
        product.options.forEach((option) => {
            /*
             * prioritize previously selected color option
             * else it will select the first color option from list
             */
            if (option.options.length) {
                if (option.options.length === 1) {
                    selections[option.id] = option.options[0].id;
                } else if (!this.data?.selections[option.id] && option.label.toLowerCase() === 'color') {
                    selections[option.id] = option.options[0].id;
                }
            }
        });

        this.setState({ selections }, this.doEffect);
    }

    doEffect() {
        const { loadProduct } = this.props;
        const { product } = this.props;

        if (this.simpleProductId) {
            loadProduct(this.simpleProductId, product.id, this.cartItem);
        }

        if (this.colorProductId) {
            loadProduct(this.colorProductId, product.id, this.cartItem);
        }
    }

    selectOption(k, v) {
        this.setState(s => ({
            selections: {
                ...s.selections,
                [k]: v,
            },
        }), this.doEffect);
    }

    protected async addItemToCart(repository: ICartRepository, item?: SilkRestappDataCartCartItemInterface|ICartItem) {
        const { product } = this.props;
        await repository.addSimpleProduct(
            this.simpleProductId,
            this.quantity.current,
            product.id,
            /**
             * See description and logic in parent method `updateItem` and `addItemToCart` in:
             * `../base-product/base-product.tsx`
             */
            item,
        );
    }

    async addToCart(repository: ICartRepository) {
        const {
            itemId,
            close,
            enqueue,
            t,
        } = this.props;

        try {
            if (!checkSelections(this.data)) {
                enqueue({
                    type: 'primary',
                    message: t('Not all required options were selected'),
                    time: 5000,
                });
                return;
            }
            const { enabledButton } = getEnableCommitInfo(this.data);
            if (!enabledButton) {
                enqueue({
                    type: 'primary',
                    message: t('This product is not available'),
                    time: 5000,
                });
                return;
            }
            if (!this.simpleProductId) throw new Error(t('Need to select a product'));

            const { minQuantity, maxQuantity } = this.simpleProduct;

            if (itemId && this.quantity.current === 0) {
                await this.removeItem(repository);
            } else if (this.quantity.current < minQuantity) {
                enqueue({
                    type: 'primary',
                    message: t('Cannot add less than %1 of this item to your cart.', minQuantity),
                    time: 5000,
                });
                throw new Error(t('Too little quantity'));
            } else if (this.quantity.current > maxQuantity) {
                enqueue({
                    type: 'primary',
                    message: t('Cannot add more than %1 of this item to your cart.', maxQuantity - 1),
                    time: 5000,
                });
                throw new Error(t('Too much quantity'));
            } else if (itemId) {
                await this.updateItem(repository);
            } else {
                await this.addNewItem(repository);
            }

            if (close) close();
        } finally {
            this._adding = false;
        }
    }

    render() {
        const { children } = this.props;
        this.data = {
            ...this.renderData(),
            type: 'ConfigurableConfig',
            attributes: this.attributes,
            validation: this.validation,
        };

        return (
            <RenderConfigurator data={this.data}>
                {children}
            </RenderConfigurator>
        );
    }
}
