import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';

import ChefworksFilesUploaderDataUploadedImageInterface =
    Magento.Definitions.ChefworksFilesUploaderDataUploadedImageInterface;

export interface IUploadImagesResponse extends ChefworksFilesUploaderDataUploadedImageInterface {}

export interface IFilesUploader {
    uploadImages(data: FormData): Promise<IUploadImagesResponse[]>;
}

class FilesUploader implements IFilesUploader {
    private readonly uploadImagesBaseUrl: string = '/files-uploader/upload-images';

    constructor(private magentoAPI: Http) {}

    async uploadImages(data: FormData): Promise<IUploadImagesResponse[]> {
        return this.magentoAPI.post(this.uploadImagesBaseUrl, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        });
    }
}

export const FilesUploaderCreator = new ModuleCreator<IFilesUploader>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return new FilesUploader(magentoAPI);
    },
});
