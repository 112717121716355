import React, { useRef, useState, useEffect } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import uploadWhiteIcon from 'assets/images/upload_white.svg';
import { UploadStatus } from './upload-status';
import styles from './style.css';

interface IMultipleFileUploaderProps {
    handleFileChange: (files: FileList) => Promise<void>;
    wrapperClassName?: string;
    acceptImages?: string;
    inputId?: string;
    inputClassName?: string;
    inputName?: string;
    inputLabel?: string;
    inputDataTest?: string;
    inputDataTestNew?: string;
    btnLabel?: string;
    btnClassName?: string;
    btnIconSrc?: string;
    btnIconClassName?: string;
    disabled?: boolean;
    showUploadStatus?: boolean;
}

export type FileUploaderStatus = 'initial' | 'uploading' | 'success' | 'fail';

export const MultipleImageUploader: React.FC<IMultipleFileUploaderProps> = (
    {
        handleFileChange,
        wrapperClassName,
        acceptImages = 'image/*',
        inputId = 'multiple-images-input',
        inputClassName,
        inputName = 'files',
        inputLabel,
        inputDataTest = 'multiple-images-upload',
        inputDataTestNew = '',
        btnLabel,
        btnClassName,
        btnIconSrc,
        btnIconClassName,
        disabled,
        showUploadStatus = false,
    },
) => {
    const [status, setStatus] = useState<FileUploaderStatus>('initial');
    const [isDisabled, setIsDisabled] = useState<boolean>(disabled ?? false);

    const t = usePhraseTranslater();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const buttonLabel = btnLabel || t('Upload Images');

    const setCurrentStatus = (status: FileUploaderStatus) => {
        if (!showUploadStatus) {
            return;
        }

        setStatus(status);
    };

    const resetInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { files, validity } = target;

        setCurrentStatus('initial');

        if (files && validity?.valid) {
            setCurrentStatus('uploading');

            handleFileChange(files).then(() => {
                setCurrentStatus('success');
            }).catch(() => {
                setCurrentStatus('fail');
            }).finally(() => {
                resetInput();
            });
            return;
        }

        resetInput();
    };

    const triggerFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if ((event.keyCode === 13 || event.keyCode === 32)) {
            triggerFileInputClick();
        }
    };

    useEffect(() => {
        if (disabled !== undefined) {
            setIsDisabled(disabled);
            return;
        }

        setIsDisabled(status === 'uploading');
    }, [status, disabled]);

    return (
        <div className={wrapperClassName || styles.multipleFileUploader}>
            {inputLabel && inputId && (
                <label htmlFor={inputId}>
                    {inputLabel}
                </label>
            )}
            <input
                id={inputId}
                ref={fileInputRef}
                className={inputClassName || styles.filesInput}
                data-test={inputDataTest}
                data-test-new={inputDataTestNew}
                type="file"
                name={inputName}
                accept={acceptImages}
                multiple
                onChange={onFileChange}
                disabled={disabled}
            />
            <button
                type="submit"
                className={classes((btnClassName || styles.uploadFilesBtn), {
                    [styles.disabled]: isDisabled,
                })}
                onClick={triggerFileInputClick}
                onKeyUp={handleKeyDown}
                aria-disabled={disabled}
                tabIndex={0}
            >
                <img
                    src={btnIconSrc || uploadWhiteIcon}
                    alt={buttonLabel}
                    className={classes((btnIconClassName || styles.uploadIcon), {
                        [styles.disabled]: isDisabled,
                    })}
                />
                <span className={styles.label}>{buttonLabel}</span>
            </button>
            {showUploadStatus && (<UploadStatus status={status} />)}
        </div>
    );
};
