import { Container } from '@silkpwa/redux';
import { MagentoAPIBase } from '@silkpwa/magento/api/base';
import { loadCMSRouter } from '@silkpwa/magento/api/cms-router';
import { loadMessageRepository } from '@silkpwa/magento/api/message-repository';
import { loadAccountRepository } from '@silkpwa/magento/api/account-repository';
import { loadCustomerNumberRepository } from '@silkpwa/magento/api/customer-number-repository';
import { loadSuperUserRepository } from '@silkpwa/magento/api/super-user-repository';
import { loadCmsRepository } from '@silkpwa/magento/api/cms-repository';
import { loadCompareRepository } from '@silkpwa/magento/api/compare-repository';
import { loadWishlistRepository } from '@silkpwa/magento/api/wishlist-repository';
import { loadEmailRepository } from '@silkpwa/magento/api/email-repository';
import { loadConfigRepository } from '@silkpwa/magento/api/config-repository';
import { loadCatalogRepository } from '@silkpwa/magento/api/catalog-repository';
import { loadOverrideCatalogRepository } from '@silkpwa/magento/api/override-catalog-repository';
import { loadCartRepository } from '@silkpwa/magento/api/cart-repository';
import { loadRewardsRepository } from '@silkpwa/magento/api/rewards-repository';
import { loadPortalRepository } from '@silkpwa/magento/api/portal-repository';
import { loadStockRepository } from '@silkpwa/magento/api/stock-repository';
import { loadGetLoginPage } from '@silkpwa/magento/api/get-login-page';
import { loadCmsHeadSelector } from '@silkpwa/magento/api/cms-head-selector';
import { loadOfflineRetry } from '@silkpwa/magento/api/offline-retry';
import { loadPLPURLSerializer } from '@silkpwa/magento/api/plp-url-serializer';
import { loadSetCurrentStore } from '@silkpwa/magento/api/set-current-store';
import { loadCaptureIdentityRepository } from '@silkpwa/magento/api/capture-identity-repository';
import { loadReviewsRepository } from '@silkpwa/magento/api/reviews-repository';
import { loadCookieConsentUpdateRepository } from '@silkpwa/magento/api/cookie-consent-update-repository';
import { loadFilesUploader } from '@silkpwa/magento/api/files-uploader';

/**
 * Loads the silkpwa Magento API adapters
 */
const loadMagentoModules = (container: Container) => {
    container.bind('magento.baseURL', process.env.MAGENTO_API);
    container.load(MagentoAPIBase({
        baseURL: process.env.MAGENTO_API,
        dynamicAxios: () => ({
            headers: {
                'X-WHERE-I-AM': window.location.href,
            },
        }),
    }));
    container.load(loadCMSRouter);
    container.load(loadMessageRepository);
    container.load(loadAccountRepository);
    container.load(loadCustomerNumberRepository);
    container.load(loadSuperUserRepository);
    container.load(loadCmsRepository);
    container.load(loadCompareRepository);
    container.load(loadWishlistRepository);
    container.load(loadEmailRepository);
    container.load(loadConfigRepository);
    container.load(loadCatalogRepository);
    container.load(loadOverrideCatalogRepository);
    container.load(loadGetLoginPage);
    container.load(loadCartRepository);
    container.load(loadRewardsRepository);
    container.load(loadPortalRepository);
    container.load(loadStockRepository);
    container.load(loadCaptureIdentityRepository);
    container.load(loadCookieConsentUpdateRepository);
    container.load(loadCmsHeadSelector);
    container.load(loadOfflineRetry);
    container.load(loadPLPURLSerializer);
    container.load(loadSetCurrentStore);
    container.load(loadReviewsRepository);
    container.load(loadFilesUploader);
};

export { loadMagentoModules };
