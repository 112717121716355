import React from 'react';
import { ICartRepository } from '@silkpwa/magento/api/cart-repository/repository';
import {
    ICartItem,
    BaseProduct,
    IBaseProductConfigProps,
} from '@silkpwa/module/react-component/product-config/base-product';
import { memoize } from '../../../util/memoize';
// import { computeSimpleProduct } from '../../../util/compute-simple-product';
import {
    computeProductAttributes,
    IProductAttribute,
    checkGiftCardSelections,
} from '../util';
import { RenderConfigurator } from '../render-configurator';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

export class GiftCardProductConfig extends BaseProduct {
    // private readonly computeSimpleProduct;

    private _minQuantity;

    private readonly computeProductAttributes;

    constructor(props: Readonly<IBaseProductConfigProps>) {
        super(props);

        this.computeProductAttributes = memoize(computeProductAttributes);
        // this.computeSimpleProduct = memoize(computeSimpleProduct);
        this.doEffect = this.doEffect.bind(this);
        this.setGiftCardOption = this.setGiftCardOption.bind(this);
        this.areGiftCardOptionsSelected = this.areGiftCardOptionsSelected.bind(this);
    }

    componentDidMount() {
        this.handleSingleOptionSelect();
        super.componentDidMount();
    }

    componentDidUpdate() {
        this.handleQuantityChange();
    }

    get attributes(): IProductAttribute[] {
        const { product } = this.props;

        return this.computeProductAttributes(
            product,
            this.selections,
            this.selectOption.bind(this),
        );
    }

    protected get simpleProductId() {
        const { product } = this.props;

        return product.productId;
    }

    get imageProductId() {
        const { product } = this.props;
        return (
            this.simpleProductId ||
            product.id
        );
    }

    handleEditItem() {
        const item = this.cartItem;
        /* eslint-enable camelcase */
        this.setState({
            selections: item.selections,
            quantity: item.qty,
        });
    }

    handleQuantityChange() {
        if (this.simpleProduct.minQuantity !== this._minQuantity) {
            this._minQuantity = this.simpleProduct.minQuantity;
            if (this.cartItem) return;
            this.setState({
                quantity: this.simpleProduct.minQuantity,
            });
        }
    }

    protected handleNewItem() {
        this.handleQuantityChange();
    }

    handleSingleOptionSelect() {
        const { product } = this.props;

        const selections = {};
        product.options.forEach((option) => {
            if (option.options.length === 1) {
                selections[option.id] = option.options[0].id;
            }
        });

        this.setState({ selections }, this.doEffect);
    }

    doEffect() {
        const { loadProduct } = this.props;
        const { product } = this.props;

        if (this.simpleProductId) {
            loadProduct(this.simpleProductId, product.id);
        }
        // For view product we needs to initialize empty selections options.
        // For edit product already have selections options
        if (!Object.keys(this.selections).length) {
            const initialSelections = {
                giftcard_amount: '',
                custom_giftcard_amount: '',
                giftcard_sender_name: '',
                giftcard_sender_email: '',
                giftcard_recipient_name: '',
                giftcard_recipient_email: '',
                giftcard_message: '',
            };

            this.setState(s => ({
                selections: {
                    ...s.selections,
                    ...initialSelections,
                },
            }));
        }
    }

    selectOption(k, v) {
        this.setState(s => ({
            selections: {
                ...s.selections,
                [k]: v,
            },
        }), this.doEffect);
    }

    setGiftCardOption(key: string, value: string) {
        this.selectOption(key, value);
    }

    areGiftCardOptionsSelected(): boolean {
        let result = true;
        const giftCardSelections = this.selections;

        if (Object.keys(giftCardSelections).length === 0) {
            return false;
        }

        Object.keys(giftCardSelections).forEach((key) => {
            if (key !== 'giftcard_message' && key !== 'custom_giftcard_amount') {
                if (giftCardSelections[key] === '') {
                    result = false;
                }
            }
            // Also check email validation here.
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (
                (key === 'giftcard_sender_email' || key === 'giftcard_recipient_email') &&
                !emailRegex.test(giftCardSelections[key])
            ) {
                result = false;
            }
        });
        if (
            giftCardSelections.giftcard_amount === 'custom' &&
            giftCardSelections.custom_giftcard_amount === ''
        ) {
            result = false;
        }

        return result;
    }

    protected async addItemToCart(repository: ICartRepository, item?: SilkRestappDataCartCartItemInterface|ICartItem) {
        const { productId } = this.props;
        if (!productId) {
            throw new Error('No Product Id assigned');
        }
        await repository.addGiftCardProduct(
            productId,
            this.selections,
            this.quantity.current,
            /**
             * See description and logic in parent method `updateItem` and `addItemToCart` in:
             * `../base-product/base-product.tsx`
             */
            item,
        );
    }

    async addToCart(repository: ICartRepository) {
        const {
            itemId,
            enqueue,
            t,
        } = this.props;
        try {
            if (!this.areGiftCardOptionsSelected() && !checkGiftCardSelections(this.data)) {
                enqueue({
                    type: 'primary',
                    message: t('Missing required field.'),
                    time: 5000,
                });
                this._adding = false;
                return;
            }

            if (itemId && this.quantity.current === 0) {
                await this.removeItem(repository);
            } else if (itemId) {
                await this.updateItem(repository);
            } else {
                await this.addNewItem(repository);
            }
        } finally {
            this._adding = false;
        }
    }

    render() {
        const { children } = this.props;
        this.data = {
            ...this.renderData(),
            type: 'GiftCardConfig',
            setGiftCardOption: this.setGiftCardOption,
            areGiftCardOptionsSelected: this.areGiftCardOptionsSelected,
        };

        return (
            <RenderConfigurator data={this.data}>
                {children}
            </RenderConfigurator>
        );
    }
}
