import React from 'react';
import { Router } from '@silkpwa/module/react-component/router';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ShowLoader } from 'ui/component/show-loader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useCart } from '@silkpwa/module/ecommerce-cart/use-cart';
import { hasPunchoutInitialSession } from '@silkpwa/module/store-mode';
import { asyncComponent } from '@silkpwa/module/async-component';
import styles from './styles.css';

const AsyncHomePage = asyncComponent(() => import('ui/page/home-page/home-page'));
const AsyncGlobalNetwork = asyncComponent(() => import('ui/page/global-network/global-network'));
const AsyncProductPage = asyncComponent(() => import('ui/page/product-page/product-page'));
const AsyncCategoryPage = asyncComponent(() => import('ui/page/category-page/category-page'));
const AsyncCartPage = asyncComponent(() => import('ui/page/cart-page'));
const AsyncCheckoutPage = asyncComponent(() => import('ui/page/checkout-page/checkout-page'));
const AsyncSuccessPage = asyncComponent(() => import('ui/page/checkout-page/success-page'));
const AsyncLogInPage = asyncComponent(() => import('ui/page/log-in-page'));
const AsyncSearchPage = asyncComponent(() => import('ui/page/search-page/search-page'));
const AsyncCMSPage = asyncComponent(() => import('ui/page/cms-page/cms-page'));
const AsyncOverrideCategory = asyncComponent(() => import('ui/page/override-category-page/override-category-page'));
const AsyncDefaultCategoryPage = asyncComponent(() => import('ui/page/default-category-page/default-category-page'));
const AsyncCustomerOrders = asyncComponent(() => import('ui/page/sales-pages/orders-page/orders-page'));
const AsyncCustomerOrderDetailsPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/order-details-page'),
);
const AsyncGuestOrderDetailsPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/order-details-page'),
);
const AsyncCustomerInvoicesPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/order-invoices-page'),
);
const AsyncGuestInvoicesPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/order-invoices-page'),
);
const AsyncCustomerShipmentsPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/order-shipments-page'),
);
const AsyncGuestShipmentsPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/order-shipments-page'),
);
const AsyncCustomerRefundsPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/order-refunds-page'),
);
const AsyncGuestRefundsPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/order-refunds-page'),
);
const AsyncCreateReturns = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/create-returns-page/create-returns-customer-page'),
);
const AsyncCreateReturnsGuest = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/create-returns-page/create-returns-guest-page'),
);
const AsyncReturnFormPage = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/return-form-page/return-form-page'),
);
const AsyncCustomerOrderPrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/order-print-page'),
);
const AsyncGuestOrderPrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/order-print-page'),
);
const AsyncCustomerInvoicePrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/invoice-print-page'),
);
const AsyncGuestInvoicePrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/invoice-print-page'),
);
const AsyncCustomerShipmentPrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/shipment-print-page'),
);
const AsyncGuestShipmentPrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/shipment-print-page'),
);
const AsyncCustomerRefundPrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/customer/refund-print-page'),
);
const AsyncGuestRefundPrintPage = asyncComponent(
    () => import('ui/page/sales-pages/order-details-page/guest/refund-print-page'),
);
const AsyncTrackingCustomerPage = asyncComponent(
    () => import('ui/page/sales-pages/tracking-page/tracking-customer-page'),
);
const AsyncTrackingGuestPage = asyncComponent(
    () => import('ui/page/sales-pages/tracking-page/tracking-guest-page'),
);
const AsyncOrderReturnsCustomerPage = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/order-returns-page/order-returns-customer-page'),
);
const AsyncOrderReturnsGuestPage = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/order-returns-page/order-returns-guest-page'),
);
const AsyncReturnViewCustomerPage = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/return-view-page/return-view-customer-page'),
);
const AsyncReturnViewGuestPage = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/return-view-page/return-view-guest-page'),
);
const AsyncReturnsListPage = asyncComponent(
    () => import('ui/page/sales-pages/rma-pages/returns-list-page/returns-list-page'),
);

interface IExpiryProps {
    expiryMessage: string;
}

// This is coming from content in the CMS, so it is fine.
/* eslint-disable react/no-danger */
export const Expiry: React.FC<IExpiryProps> = ({ expiryMessage }) => (
    <div className={styles.expiryMessage}>
        <div dangerouslySetInnerHTML={{ __html: expiryMessage }} />
    </div>
);
/* eslint-enable */

const Page = () => {
    const t = usePhraseTranslater();
    const config = useConfig();
    const cart = useCart();

    if (config.extension_attributes) {
        const isPunchoutMessageEnabled = config.extension_attributes.punchout_session_message_enabled || false;
        const sessionMessage = config.extension_attributes.punchout_session_message || '';
        const hasSession = cart.hasCustomer || hasPunchoutInitialSession();

        if (isPunchoutMessageEnabled && sessionMessage && !hasSession) {
            return <Expiry expiryMessage={sessionMessage} />;
        }
    }
    return (
        <Router
            homepage={<AsyncHomePage />}
            global={<AsyncGlobalNetwork />}
            product={<AsyncProductPage />}
            category={<AsyncCategoryPage />}
            default_category={<AsyncDefaultCategoryPage />}
            cart={<AsyncCartPage />}
            checkout={<AsyncCheckoutPage />}
            success={<AsyncSuccessPage />}
            login={<AsyncLogInPage />}
            search={<AsyncSearchPage />}
            cms={<AsyncCMSPage />}
            override_category={<AsyncOverrideCategory />}
            sales_order_history={<AsyncCustomerOrders />}
            sales_order_view={<AsyncCustomerOrderDetailsPage />}
            sales_guest_view={<AsyncGuestOrderDetailsPage />}
            sales_order_invoice={<AsyncCustomerInvoicesPage />}
            sales_guest_invoice={<AsyncGuestInvoicesPage />}
            sales_order_shipment={<AsyncCustomerShipmentsPage />}
            sales_guest_shipment={<AsyncGuestShipmentsPage />}
            sales_order_creditmemo={<AsyncCustomerRefundsPage />}
            sales_guest_creditmemo={<AsyncGuestRefundsPage />}
            rma_returns_create={<AsyncCreateReturns />}
            rma_guest_create={<AsyncCreateReturnsGuest />}
            rma_returns_returns={<AsyncOrderReturnsCustomerPage />}
            rma_guest_returns={<AsyncOrderReturnsGuestPage />}
            rma_returns_view={<AsyncReturnViewCustomerPage />}
            rma_guest_view={<AsyncReturnViewGuestPage />}
            rma_returns_history={<AsyncReturnsListPage />}
            return_form={<AsyncReturnFormPage />}
            sales_order_print={<AsyncCustomerOrderPrintPage />}
            sales_guest_print={<AsyncGuestOrderPrintPage />}
            sales_order_printInvoice={<AsyncCustomerInvoicePrintPage />}
            sales_guest_printInvoice={<AsyncGuestInvoicePrintPage />}
            sales_order_printShipment={<AsyncCustomerShipmentPrintPage />}
            sales_guest_printShipment={<AsyncGuestShipmentPrintPage />}
            sales_order_printCreditmemo={<AsyncCustomerRefundPrintPage />}
            sales_guest_printCreditmemo={<AsyncGuestRefundPrintPage />}
            shipping_tracking_customerview={<AsyncTrackingCustomerPage />}
            shipping_tracking_guestview={<AsyncTrackingGuestPage />}
            notfound={<p>{t('Not Found...')}</p>}
            loadingFallback={(
                <div className={styles.loader}>
                    <ShowLoader message={t('Loading page')} />
                </div>
            )}
        />
    );
};

export const Content = () => (
    <div className={styles.content}>
        <Page />
    </div>
);
