import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from './fragments';

export interface IToken {
    // eslint-disable-next-line camelcase
    public_hash: string;
    // eslint-disable-next-line camelcase
    payment_method_code: string;
    type: 'card' | 'account';
    details: string; // ITokenDetails below describes `details` if use JSON.parse
}

export interface ITokenDetails {
    type: string;
    maskedCC: string;
    expirationDate: string;
}

export interface IParsedToken extends IToken {
    tokenDetails?: ITokenDetails;
}

export interface IPaymentMethod {
    code: string;
    title: string;
}

export interface IPaymentMethodsInput {
    cartId: string;
}

export interface IPaymentMethodsOutput {
    cart: {
        // eslint-disable-next-line camelcase
        available_payment_methods: IPaymentMethod[];
    };
}

export interface ICustomerTokensOutput {
    customerPaymentTokens: {
        items: IToken[];
    };
}

export const GET_PAYMENT_METHODS: TypedDocumentNode<IPaymentMethodsOutput, IPaymentMethodsInput> = gql`
    query GET_PAYMENT_METHODS(
        $cartId: String!
    ) {
        cart(
            cart_id: $cartId
        ) {
            available_payment_methods {
                code
                title
            }
        }
    }
`;

export const SET_PAYMENT_METHOD: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_PAYMENT_METHOD(
        $cartId: String!
        $paymentMethodCode: String!
        $purchaseOrderNumber: String
        $cardType: String
        $cardHolderName: String
        $cardNumber: String
        $expiryMonth: String
        $expiryYear: String
        $cvv: String
        $isActivePaymentTokenEnabler: String
    ){
        setPaymentMethodOnCart(input: {
            cart_id: $cartId
            payment_method: {
                code: $paymentMethodCode
                purchase_order_number: $purchaseOrderNumber
                additional_data: {
                    cc_type: $cardType
                    cc_holder_name: $cardHolderName
                    cc_number: $cardNumber
                    cc_exp_month: $expiryMonth
                    cc_exp_year: $expiryYear
                    cc_cid: $cvv
                    is_active_payment_token_enabler: $isActivePaymentTokenEnabler
                }
            }
        }) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const SET_PAYMENT_METHOD_BRAINTREE: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_PAYMENT_METHOD(
        $cartId: String!
        $paymentMethodCode: String!
        $purchaseOrderNumber: String
        $isActivePaymentTokenEnabler: Boolean!
        $paymentMethodNonce: String!
    ){
        setPaymentMethodOnCart(input: {
            cart_id: $cartId
            payment_method: {
                code: $paymentMethodCode
                purchase_order_number: $purchaseOrderNumber
                braintree: {
                    is_active_payment_token_enabler: $isActivePaymentTokenEnabler
                    payment_method_nonce: $paymentMethodNonce
                }
            }
        }) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const SET_PAYMENT_METHOD_BRAINTREE_VAULT: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_PAYMENT_METHOD(
        $cartId: String!
        $paymentMethodCode: String!
        $purchaseOrderNumber: String
        $publicHash: String!
    ){
        setPaymentMethodOnCart(input: {
            cart_id: $cartId
            payment_method: {
                code: $paymentMethodCode
                purchase_order_number: $purchaseOrderNumber
                braintree_cc_vault: {
                    public_hash: $publicHash
                }
            }
        }) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const GET_CUSTOMER_TOKENS: TypedDocumentNode<ICustomerTokensOutput, {}> = gql`
    query GET_CUSTOMER_TOKENS {
        customerPaymentTokens {
            items {
                public_hash
                payment_method_code
                type
                details
            }
        }
    }
`;
