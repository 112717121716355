/**
 * Get Url object form current location.href
 */
export const getUrl = (): URL => new URL(window.location.href);

/**
 * Get query param value by a name
 *
 * @param name
 */
export const getQueryParamByName = (name: string): string|null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
};

export const getPrettyParamFromUrl = (name: string): string => {
    const url: URL = getUrl();
    const regExp = new RegExp(`${name}\\/(\\d+)`, 'gi');
    const matched = url.pathname.match(regExp);
    if (matched && matched.length > 0) {
        const foundString = matched[0];
        return foundString.replace(`${name}/`, '');
    }

    return '';
};
