import React, { useState, useEffect } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { FileUploaderStatus } from '../multiple-image-uploader';
import styles from './style.css';

interface IUploadStatusProps {
    status: FileUploaderStatus;
}

export const UploadStatus: React.FC<IUploadStatusProps> = ({ status }) => {
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const t = usePhraseTranslater();

    const getStatus = () => {
        switch (status) {
            case 'success':
                return (<p className={styles.success}>{t('Images uploaded successfully!')}</p>);
            case 'fail':
                return (<p className={styles.fail}>{t('Images upload failed!')}</p>);
            case 'uploading':
                return (<p className={styles.uploading}>{t('Uploading selected images...')}</p>);
            default:
                return null;
        }
    };

    useEffect(() => {
        setShowStatus(status !== 'initial');
        setTimeout(() => {
            setShowStatus(false);
        }, 5000);
    }, [status]);

    return (
        <div
            className={classes(styles.statusWrapper, {
                [styles.fadeIn]: showStatus,
                [styles.fadeOut]: !showStatus,
            })}
        >
            <div className={styles.status}>
                {getStatus()}
            </div>
        </div>
    );
};
